import Color from '@arcgis/core/Color';
import Geometry from '@arcgis/core/geometry/Geometry';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as projection from '@arcgis/core/geometry/projection';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Layer from '@arcgis/core/layers/Layer';
import PortalItem from '@arcgis/core/portal/PortalItem';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import SizeVariable from '@arcgis/core/renderers/visualVariables/SizeVariable';
import SketchEdges3D from '@arcgis/core/symbols/edges/SketchEdges3D';
import SolidEdges3D from '@arcgis/core/symbols/edges/SolidEdges3D';
import ExtrudeSymbol3DLayer from '@arcgis/core/symbols/ExtrudeSymbol3DLayer';
import PolygonSymbol3D from '@arcgis/core/symbols/PolygonSymbol3D';
import SceneView from '@arcgis/core/views/SceneView';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { Modal } from '@jll/react-ui-components';
import { isEmpty } from 'lodash';

import { PropertyCore } from 'interfaces/Property';
import ExtendedSketchViewModel from 'types/esri/ExtendedSketchViewModel';
import endpoints from 'utils/apiClient/endpoints';
import config from 'utils/config';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import isDefined from 'utils/isDefined';
import { getUniqueValuesFromRenderer } from './osmStylesHelper';
import {
    MISSING_POLYGONS_PINS_LAYER,
    queryMissingPropertiesLayer,
    searchBlackbirdIdsByMarketSphereIds,
} from './searchHelper';
import { createOpacityVisualVariable } from './visualVariableHelper';

export const POLYGON_EDITOR_SKETCH_LAYER_ID = 'polygon-editor-sketch-layer';
export const DEFAULT_POLYGON_HEIGHT_IN_METERS = 42.06;

const BUILDING_SKETCH_COLOR = [206, 214, 216, 0.7];
export const BUILDING_EDIT_COLOR = [206, 214, 216, 1];
export const BUILDING_EDGE_STYLE = {
    size: 0.25,
    color: [0, 0, 0],
};

export type HeightUnit = 'ft' | 'm';

export const createExtrudedPolygon = (height: number, buildingColor: Color) => {
    return new PolygonSymbol3D({
        symbolLayers: [
            {
                type: 'extrude',
                size: height,
                material: { color: buildingColor },
                edges: new SolidEdges3D({
                    color: BUILDING_EDGE_STYLE.color,
                    size: BUILDING_EDGE_STYLE.size,
                }),
            },
        ],
    });
};

export const createSketchExtrudedPolygon = (
    height: number,
    buildingColor = BUILDING_SKETCH_COLOR
) => {
    return new PolygonSymbol3D({
        symbolLayers: [
            {
                type: 'extrude',
                size: height,
                material: { color: buildingColor },
                edges: new SketchEdges3D({
                    color: [82, 82, 122, 1],
                    size: 1,
                }),
            },
        ],
    });
};

export const convertHeightToMeters = (value: number) => {
    const ftToMeters = 0.3048;
    return Math.round(value * ftToMeters * 100) / 100;
};

export const convertHeightFromMeters = (value = DEFAULT_POLYGON_HEIGHT_IN_METERS) => {
    const metersToFt = 3.28;
    return Math.round(value * metersToFt);
};

export const createBuildingEditsUniqueValueGroupsRenderer = (
    uniqueValueGroups: UniqueValueGroup[],
    defaultColor = new Color(BUILDING_EDIT_COLOR),
    defaultHeight = DEFAULT_POLYGON_HEIGHT_IN_METERS,
    field = 'BlackbirdId'
) => {
    return new UniqueValueRenderer({
        field: field,
        defaultSymbol: createExtrudedPolygon(defaultHeight, defaultColor),
        uniqueValueGroups: uniqueValueGroups,
        visualVariables: [
            new SizeVariable({
                field: 'Height',
                valueExpression: '$feature.Height',
            }),
            createOpacityVisualVariable(),
        ],
    });
};

const createUniqueValueRenderer = (height: number, color = new Color(BUILDING_EDIT_COLOR)) => {
    return new UniqueValueRenderer({
        field: 'OBJECTID',
        defaultSymbol: createExtrudedPolygon(height, color),
        visualVariables: [
            new SizeVariable({
                field: 'Height',
                valueExpression: '$feature.Height',
            }),
            createOpacityVisualVariable(),
        ],
    });
};

export const createPolygonEditorSketchViewModal = (
    view: SceneView,
    height: number
): ExtendedSketchViewModel => {
    let sketchLayer = findMapLayer(POLYGON_EDITOR_SKETCH_LAYER_ID) as GraphicsLayer;
    if (!sketchLayer) {
        sketchLayer = new GraphicsLayer({
            id: POLYGON_EDITOR_SKETCH_LAYER_ID,
            elevationInfo: {
                mode: 'relative-to-ground',
            },
        });
        view.map.add(sketchLayer);
    }

    const sketchViewModel = new SketchViewModel({
        layer: sketchLayer,
        view: view,
        polygonSymbol: createSketchExtrudedPolygon(height),
        snappingOptions: {
            enabled: true,
            featureSources: [{ layer: sketchLayer }],
        },
        tooltipOptions: {
            enabled: false,
        },
        labelOptions: {
            enabled: false,
        },
        defaultUpdateOptions: {
            tool: 'reshape',
            enableZ: true,
            reshapeOptions: {
                edgeOperation: 'split',
                shapeOperation: 'move',
            },
        },
        defaultCreateOptions: {
            hasZ: true,
        },
    });

    return sketchViewModel;
};

export const getCoordinatesStringFromGraphics = (graphics: Graphic[]) => {
    const geometries = new Array(graphics.length);
    const outSpatialReference = new SpatialReference({
        wkid: 4326,
    });
    const projectedGraphics = projectGraphicsGeometry(graphics, outSpatialReference);
    for (let i = 0; i < projectedGraphics.length; i++) {
        const graphic = projectedGraphics[i];
        const heightInMeters = getGraphicHeight(graphic);
        const geometry = graphic.geometry as Polygon;
        const coordinates = geometry.rings[0];
        const coordsArray = new Array(coordinates.length);
        for (let j = 0; j < coordinates.length; j++) {
            const coordinate = coordinates[j];
            coordsArray[j] = `${coordinate[0]},${coordinate[1]},${heightInMeters}`;
        }
        geometries[i] = coordsArray.join(' ');
    }
    return geometries.join(';');
};

export const projectGraphicsGeometry = (
    graphics: Graphic[],
    spatialReference: SpatialReference
) => {
    graphics.forEach(function (graphic) {
        graphic.geometry = projection.project(graphic.geometry, spatialReference) as Geometry;
    });
    return graphics;
};

export const fetchBuildingFootprints = async (blackbirdId: number) => {
    const response = await endpoints.propertyGeometries.getPropertyGeometryByBlackbirdId.get({
        templateValues: { blackbirdId },
    });
    return await response.json();
};

export const fetchModifiedPolygons = async () => {
    const response = await endpoints.propertyGeometries.getModifiedPropertyCorePolygons.get();
    return await response.json();
};

const onSavePolygon = async (propertyCore: PropertyCore) => {
    const response = await endpoints.propertyGeometries.propertyCore.post({
        queryParameters: {
            propertyProviderId: 4,
        },
        fetchOptions: {
            body: JSON.stringify(propertyCore),
        },
    });
    if ('err' in response) {
        showErrorMessage();
        throw new Error('Problem saving in geometry');
    }
    return await response.text();
};

const showErrorMessage = () => {
    Modal.error({
        title: 'Save failed',
        content: 'Could not save the building, try editing and saving again.',
        mask: false,
    });
};

export const savePropertyCore = async (
    graphics: Graphic[],
    marketSpherePropertyId: string,
    blackbirdId: number
) => {
    const coordinateString = getCoordinatesStringFromGraphics(graphics);
    if (!isEmpty(marketSpherePropertyId)) {
        const propertyCore: PropertyCore = {
            Coordinates: coordinateString,
            PropertyId: marketSpherePropertyId,
            BlackbirdId: blackbirdId,
            Extrusions: '1',
        };

        return await onSavePolygon(propertyCore);
    }
};

export const updateSketchHeight = (sketchViewModel: SketchViewModel, heightInMeters: number) => {
    const sl = (sketchViewModel.polygonSymbol as PolygonSymbol3D).symbolLayers
        .getItemAt(0)
        .clone() as ExtrudeSymbol3DLayer;
    sl.size = heightInMeters;

    sketchViewModel.polygonSymbol = new PolygonSymbol3D({
        symbolLayers: [sl],
    });
};

export const getGraphicHeight = (graphic: Graphic) => {
    return graphic.getAttribute('Height');
};

export const setGraphicHeight = (graphic: Graphic, heightInMeters: number) => {
    if (graphic.symbol) {
        const symbol = graphic.symbol as PolygonSymbol3D;
        const newSymbol = symbol.clone();
        const item = newSymbol.symbolLayers.getItemAt(0) as ExtrudeSymbol3DLayer;
        item.size = heightInMeters;
        graphic.symbol = newSymbol;
    }

    if (graphic.attributes == null) {
        graphic.attributes = {};
    }
    graphic.setAttribute('Height', heightInMeters);
};

export const getGraphicArea = (graphic: Graphic) => {
    if (!graphic.geometry) return 0;
    return geometryEngine.geodesicArea(graphic.geometry as Polygon, 'square-feet');
};

export const changeGraphicColor = (graphic: Graphic, color: string) => {
    const symbol = graphic.symbol as PolygonSymbol3D;
    const newSymbol = symbol.clone();
    const item = newSymbol.symbolLayers.getItemAt(0) as ExtrudeSymbol3DLayer;

    const polyColor = Color.fromHex(`#${color}`);
    item.material.color = polyColor;
    item.material.color.a = 0.5;

    const outlineColor = polyColor;
    outlineColor.a = 1;
    item.edges.color = outlineColor;

    graphic.symbol = newSymbol;
};

export const createBuildingEditsLayer = async () => {
    if (findMapLayer(config.buildingEditsLayerItemId)) return;

    const height = DEFAULT_POLYGON_HEIGHT_IN_METERS;

    const layer = (await Layer.fromPortalItem({
        portalItem: new PortalItem({
            id: config.buildingEditsLayerItemId,
        }),
    })) as FeatureLayer;

    layer.id = config.buildingEditsLayerItemId;
    layer.legendEnabled = false;
    layer.popupEnabled = false;
    layer.outFields = ['*'];
    layer.hasZ = true;
    layer.returnZ = true;
    layer.elevationInfo = {
        mode: 'relative-to-ground',
    };
    layer.renderer = createUniqueValueRenderer(height);
    return layer;
};

export const applyEditsToLayer = async (graphics: Graphic[], sourceGraphics: Graphic[]) => {
    const editsLayer = getBuildingEditsLayer();
    if (!editsLayer) return;

    graphics.forEach((graphic) => {
        graphic.geometry = webMercatorUtils.geographicToWebMercator(graphic.geometry);
    });

    const objectIdField = editsLayer.objectIdField || 'OBJECTID';

    const addFeatures = [];
    const deleteFeatures = [];

    const layerId = sourceGraphics[0]?.layer?.id;
    const objectIds =
        layerId && isBuildingEditsLayer(layerId)
            ? (sourceGraphics
                  .map((graphic) => graphic.attributes[objectIdField])
                  .filter(isDefined) as number[])
            : [];

    if (objectIds.length) {
        const existingFeatures = await queryBuildingEditsLayer(objectIds, objectIdField);
        if (existingFeatures?.length) {
            deleteFeatures.push(...existingFeatures);
        }
    }

    addFeatures.push(...graphics);
    await editsLayer.applyEdits({ addFeatures, deleteFeatures });
};

export const deleteFeaturesFromEditsLayer = async (objectIds: number[]) => {
    const editsLayer = getBuildingEditsLayer();
    if (!editsLayer) return;
    const existingFeatures = await queryBuildingEditsLayer(objectIds, 'OBJECTID');
    if (existingFeatures?.length) {
        await editsLayer.applyEdits({ deleteFeatures: existingFeatures });
    }
};

export const isBuildingEditsLayer = (layerId: string) => {
    return layerId === config.buildingEditsLayerItemId;
};

export const getBuildingEditsLayer = () => {
    return findMapLayer(config.buildingEditsLayerItemId) as FeatureLayer;
};

export const createBuildingGraphicToEdit = async (
    marketSpherePropertyId: number,
    height: number
) => {
    const marketSphereBlackbirdMappings = await searchBlackbirdIdsByMarketSphereIds([
        marketSpherePropertyId,
    ]);

    if (marketSphereBlackbirdMappings.length) {
        const blackbirdId = marketSphereBlackbirdMappings[0].blackbirdId;
        return new Graphic({
            attributes: {
                MarketSpherePropertyId: marketSpherePropertyId,
                BlackbirdId: blackbirdId,
                Height: height,
            },
            symbol: createSketchExtrudedPolygon(height),
        });
    }
};

export const createSkeletonGraphicToEdit = (marketSpherePropertyId: number) => {
    const graphics: Graphic[] = [];
    const layer = findMapLayer(MISSING_POLYGONS_PINS_LAYER) as FeatureLayer;
    if (!layer) return graphics;
    graphics.push(
        new Graphic({
            attributes: {
                MarketSpherePropertyId: marketSpherePropertyId,
            },
            layer,
        })
    );
    return graphics;
};

export const searchFeaturesFromBuildingEditsLayer = async (
    outFields: string[] = ['MarketSpherePropertyId']
): Promise<{ [key: string]: number[] }> => {
    const layer = getBuildingEditsLayer();
    if (!layer) return {};

    const output = await layer.queryFeatures({
        where: '1=1',
        outFields,
        returnGeometry: false,
    });

    if (!output.features?.length) return {};

    const acc: { [key: string]: number[] } = {};
    outFields.forEach((field) => {
        acc[field] = output.features
            .map((feature) => Number(feature.getAttribute(field)))
            .filter(isFinite);
    });
    return acc;
};

export const hideFeaturesInBuildingEditsLayer = async (marketSpherePropertyIds: number[]) => {
    const layer = getBuildingEditsLayer();
    if (layer) {
        layer.definitionExpression = `MarketSpherePropertyId NOT IN (${marketSpherePropertyIds.join(
            ','
        )})`;
    }
};

export const queryBuildingEditsLayer = async (ids?: number[], field = 'MarketSpherePropertyId') => {
    if (!ids?.length) return [];
    const layer = getBuildingEditsLayer();
    if (layer) {
        const query = layer.createQuery();
        query.returnZ = true;
        query.where = `${field} in (${ids.join(',')})`;
        const result = await layer.queryFeatures(query);
        return result.features;
    }
};

export const searchMarketSphereIdByBlackbirdId = async (blackbirdId: number) => {
    if (!blackbirdId) return;
    const features = await queryBuildingEditsLayer([blackbirdId], 'BlackbirdId');
    if (!features?.length) return;
    return features[0].getAttribute('MarketSpherePropertyId');
};

export const showAllFeatures = async () => {
    const layer = getBuildingEditsLayer();
    if (layer) {
        layer.definitionExpression = `1=1`;
    }
};

export const getMissingPolygonGraphics = async (marketSpherePropertyIds: number[]) => {
    const missingProperties = await queryMissingPropertiesLayer(marketSpherePropertyIds, true);
    const features = missingProperties?.features?.length
        ? missingProperties.features
        : createSkeletonGraphicToEdit(marketSpherePropertyIds[0]);
    return features;
};

export const getDevPipelineColor = (allPropertiesChecked: boolean, defaultColor: Color) => {
    const buildingColorWithOpacity = new Color(BUILDING_EDIT_COLOR);
    buildingColorWithOpacity.a = 0.5;
    return allPropertiesChecked ? buildingColorWithOpacity : new Color(defaultColor);
};

export const setLayerRendererAndVisibility = (
    uniqueValueGroups: UniqueValueGroup[],
    graphicsToUpdate: Graphic[],
    polygonColor: Color,
    visible: boolean
) => {
    const layer = findMapLayer(config.buildingEditsLayerItemId) as FeatureLayer;
    if (!layer) return;

    const blackbirdIds = graphicsToUpdate
        .map((item) => item?.attributes?.BlackbirdId)
        .filter(isDefined) as number[];

    layer.renderer = createBuildingEditsUniqueValueGroupsRenderer(uniqueValueGroups, polygonColor);

    const uniqueValuesFromRenderer = getUniqueValuesFromRenderer(
        layer.renderer as UniqueValueRenderer
    );

    if (visible) {
        layer.definitionExpression =
            blackbirdIds.length > 0 ? `BlackbirdId NOT IN (${blackbirdIds.join(',')})` : '1=1';
    } else {
        const uniqueValues = uniqueValuesFromRenderer ?? [];
        layer.definitionExpression =
            uniqueValues.length > 0 ? `BlackbirdId IN (${uniqueValues.join(',')})` : '0=1';
    }
};
