import Point from '@arcgis/core/geometry/Point';
import { UniqueValueRenderer } from '@arcgis/core/renderers';
import UniqueValue from '@arcgis/core/renderers/support/UniqueValue';
import UniqueValueClass from '@arcgis/core/renderers/support/UniqueValueClass';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import { PointSymbol3D } from '@arcgis/core/symbols';

import { AMENITY_CATEGORIES_CONFIG } from 'staticConfigs/amenitiesConfig';
import AmenityLayerMetadata from 'types/Layers/AmenityLayerMetadata';
import { LibraryAmenitiesSaveState } from 'types/Layers/LibraryItemSaveState';
import { AmenityTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import { nanoid } from 'utils/idUtils';

const defaultSymbol = new PointSymbol3D({
    symbolLayers: [
        {
            type: 'icon',
            size: 20,
            resource: { primitive: 'circle' },
            material: { color: [0, 0, 0, 0.5] },
        },
    ],
});

const iconSize = 42;

export const AMENITY_LAYER_ID = '25e2eff11a644dc5969e223a060519d3';
export const AMENITIES_LAYER_URL =
    'https://services6.arcgis.com/Do88DoK2xjTUCXd1/arcgis/rest/services/OSM_NA_Amenities/FeatureServer';

export const getAmenityLayerNodeBounds = (libraryItem: AmenityTreeItem) => {
    const metadata = libraryItem.metadata;
    if (metadata) {
        return (
            metadata.centerLatLng &&
            new Point({ latitude: metadata.centerLatLng.lat, longitude: metadata.centerLatLng.lng })
        );
    }
};

export const createAmenitiesUniqueValueRenderer = () => {
    const uniqueValueGroups = AMENITY_CATEGORIES_CONFIG.map(
        (category) =>
            new UniqueValueGroup({
                classes: [
                    new UniqueValueClass({
                        values: category.categories.map(
                            (category) => new UniqueValue({ value: category })
                        ),
                        symbol: new PointSymbol3D({
                            symbolLayers: [
                                {
                                    type: 'icon',
                                    resource: { href: category.icon },
                                    size: iconSize,
                                },
                            ],
                        }),
                    }),
                ],
            })
    );
    return new UniqueValueRenderer({ field: 'amenity', uniqueValueGroups, defaultSymbol });
};

export const createAmenityLibraryItem = (layer?: LibraryAmenitiesSaveState): AmenityTreeItem => {
    const key = layer?.guid ?? `Amenity--${nanoid()}`;

    const children = layer?.options
        ? JSON.parse(layer.options)
        : AMENITY_CATEGORIES_CONFIG.map((category) => {
              return {
                  key: `${key}--${nanoid()}`,
                  title: category.title,
                  itemType: 'Amenity',
                  isLeaf: true,
                  checked: false,
                  ownerId: key,
                  metadata: {
                      alias: category.key,
                      searchString: category.categories,
                  } as AmenityLayerMetadata,
              } as AmenityTreeItem;
          });

    return {
        key: key,
        id: 0,
        children,
        title: 'Amenities',
        itemType: 'Amenity',
        checked: layer?.active,
    } as AmenityTreeItem;
};
