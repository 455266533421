import { useCallback } from 'react';
import Geometry from '@arcgis/core/geometry/Geometry';
import Graphic from '@arcgis/core/Graphic';
import Layer from '@arcgis/core/layers/Layer';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import SceneLayerView from '@arcgis/core/views/layers/SceneLayerView';

import {
    DEV_PIPELINE_HIGHLIGHT_SCENE_LAYER_ID,
    DEV_PIPELINE_PINS_ID,
    DEV_PIPELINE_SCENE_LAYER_ID,
    findMarketSphereIdFromBlackbirdId,
} from 'helpers/devPipelineHelper';
import { OSM_PINS_LAYER_ID, OSM_SCENE_LAYER_ID } from 'helpers/osmBuildingLayerHelper';
import { searchMarketSphereIdByBlackbirdId } from 'helpers/polygonEditorHelper';
import { BUILDING_EDITS_PINS_LAYER_ID } from 'helpers/polygonEditorPinHelper';
import { MISSING_POLYGONS_PINS_LAYER, SEARCH_PINS_LAYER } from 'helpers/searchHelper';
import { selectMappingLookupByOsmId } from 'store/marketSphereOsmMappingSlice';
import { useAppSelector } from 'types/hooks';
import { BuildingGeometryRef } from 'types/Layers/BuildingGeometryRef';
import config from 'utils/config';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import { getLayerView } from 'utils/esri/layerViewUtils';
import {
    BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY,
    DEV_PIPELINE_MAP_CLICK_EVENT_KEY,
    OSM_BUILDINGS_MAP_CLICK_EVENT_KEY,
} from '../map/useMapClickEvents';

export type SceneLayerSource =
    | 'dev-pipeline'
    | 'highlighted-dev-pipeline'
    | 'osm'
    | 'osm-pins'
    | 'search-result-pins'
    | 'missing-pins'
    | 'devpipeline-pins'
    | 'building-edits-layer'
    | 'building-edits-highlight-pin-layer';

const sceneLayerIdMappings: Record<SceneLayerSource, string> = {
    'dev-pipeline': DEV_PIPELINE_SCENE_LAYER_ID,
    'highlighted-dev-pipeline': DEV_PIPELINE_HIGHLIGHT_SCENE_LAYER_ID,
    osm: OSM_SCENE_LAYER_ID,
    'osm-pins': OSM_PINS_LAYER_ID,
    'search-result-pins': SEARCH_PINS_LAYER,
    'missing-pins': MISSING_POLYGONS_PINS_LAYER,
    'devpipeline-pins': DEV_PIPELINE_PINS_ID,
    'building-edits-layer': config.buildingEditsLayerItemId,
    'building-edits-highlight-pin-layer': BUILDING_EDITS_PINS_LAYER_ID,
};

interface SourceType {
    name: string;
    source: SceneLayerSource;
    eventKey: string;
}

export const sourceTypes: SourceType[] = [
    { name: 'DevPipeline', source: 'dev-pipeline', eventKey: DEV_PIPELINE_MAP_CLICK_EVENT_KEY },
    { name: 'OSM', source: 'osm', eventKey: OSM_BUILDINGS_MAP_CLICK_EVENT_KEY },
    {
        name: 'building-edits-layer',
        source: 'building-edits-layer',
        eventKey: BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY,
    },
];

const getLayerBySceneLayerSource = (sceneLayer: SceneLayerSource) => {
    const layerId = sceneLayerIdMappings[sceneLayer];
    return layerId ? findMapLayer(layerId) : undefined;
};

export const getSceneLayerSourceByLayerId = (layerId?: string): SceneLayerSource | undefined => {
    if (!layerId) return;
    const entry = Object.entries(sceneLayerIdMappings).find(([_, value]) => value === layerId);
    return entry ? (entry[0] as SceneLayerSource) : undefined;
};

interface BuildingWithPin {
    buildingHighlight?: IHandle;
    pinHighlight?: IHandle;
}

interface QueryBuildingResult {
    resultView: FeatureLayerView | SceneLayerView;
    features: Graphic[];
}

export interface BuildingQueryProps {
    layerId: string;
    oidField?: string;
    isSceneLayer: boolean;
    query?: string;
    geometry?: Geometry;
    spatialRelationships?: string;
    outFields?: string[];
    returnGeometry?: boolean;
}

interface LayerMapping extends BuildingQueryProps {
    queryField: string;
}

const layerMappings: Record<string, LayerMapping> = {
    'dev-pipeline': {
        layerId: DEV_PIPELINE_SCENE_LAYER_ID,
        isSceneLayer: true,
        queryField: 'BlackbirdId',
    },
    'highlighted-dev-pipeline': {
        layerId: DEV_PIPELINE_HIGHLIGHT_SCENE_LAYER_ID,
        isSceneLayer: true,
        queryField: 'BlackbirdId',
    },
    'devpipeline-pins': {
        layerId: DEV_PIPELINE_PINS_ID,
        isSceneLayer: false,
        queryField: 'BlackbirdId',
    },
    osm: {
        layerId: OSM_SCENE_LAYER_ID,
        isSceneLayer: true,
        queryField: 'OSMID',
    },
    'osm-pins': {
        layerId: OSM_PINS_LAYER_ID,
        isSceneLayer: true,
        queryField: 'osmId',
    },
    'search-result-pins': {
        layerId: SEARCH_PINS_LAYER,
        isSceneLayer: false,
        queryField: 'MarketSpherePropertyId',
    },
    'missing-pins': {
        layerId: MISSING_POLYGONS_PINS_LAYER,
        isSceneLayer: false,
        queryField: 'marketSpherePropertyId',
    },
    'building-edits-layer': {
        layerId: config.buildingEditsLayerItemId,
        isSceneLayer: false,
        queryField: 'MarketSpherePropertyId',
    },
    'building-edits-highlight-pin-layer': {
        layerId: BUILDING_EDITS_PINS_LAYER_ID,
        isSceneLayer: false,
        queryField: 'blackbirdId',
    },
};

const getPinBasedOnBuildingType = (buildingType: SceneLayerSource) => {
    if (buildingType == 'osm') return 'osm-pins';
    if (['dev-pipeline', 'highlighted-dev-pipeline'].includes(buildingType))
        return 'devpipeline-pins';
};

const createHighlightQueryProps = (
    buildingType: string,
    buildingId: number
): BuildingQueryProps | undefined => {
    const mapping = layerMappings[buildingType];

    if (!mapping) {
        return;
    }

    return {
        layerId: mapping.layerId,
        isSceneLayer: mapping.isSceneLayer,
        query: `${mapping.queryField} = ${buildingId}`,
    };
};

const getLayerViewResult = async (
    layerId: string,
    isSceneLayer: boolean
): Promise<FeatureLayerView | SceneLayerView | undefined> => {
    const layer = findMapLayer(layerId);
    if (!layer) return;
    const layerView = await getLayerView(layer);

    let resultView;
    if (isSceneLayer) resultView = layerView as SceneLayerView;
    else resultView = layerView as FeatureLayerView;

    return resultView;
};

const highlightFeature = async (
    resultView: SceneLayerView | FeatureLayerView,
    query?: string
): Promise<Graphic[]> => {
    const result = await resultView.queryFeatures({
        where: query,
        returnGeometry: false,
    });

    return result.features;
};

const queryBuildingToHighlight = async ({
    layerId,
    query,
    isSceneLayer,
}: BuildingQueryProps): Promise<QueryBuildingResult | undefined> => {
    const resultView = await getLayerViewResult(layerId, isSceneLayer);
    if (!resultView) return;
    if (resultView.updating) {
        return new Promise<QueryBuildingResult | undefined>((resolve, _reject) => {
            const handle = resultView.watch('updating', async (newValue) => {
                if (!newValue) {
                    handle.remove();
                    const features = await highlightFeature(resultView, query);
                    if (!features?.length) return resolve(undefined);
                    return resolve({ resultView, features });
                }
            });
        });
    } else {
        const features = await highlightFeature(resultView, query);

        return { resultView, features };
    }
};

export const addHighlightByLayerId = async (
    layerId: string,
    features: Graphic[],
    groupKey?: string
) => {
    const layer = findMapLayer(layerId);
    if (!layer) return;
    if (groupKey) {
        layer.removeHandles(groupKey);
    }
    const layerView = (await getLayerView(layer)) as FeatureLayerView;
    const handler = layerView.highlight(features);
    if (!handler) return;
    layer.addHandles(handler);
    return handler;
};

export const addHighlightBuilding = async (
    buildingType: SceneLayerSource,
    buildingId: number,
    groupKey?: string
) => {
    if (groupKey) removeHighlightBuilding(buildingType, groupKey);
    const query = createHighlightQueryProps(buildingType, buildingId);
    if (!query) return;
    const result = await queryBuildingToHighlight(query);
    if (result) {
        const { resultView, features } = result;
        if (!features?.length) return;
        const handler = resultView.highlight(features);
        if (!handler) return;

        const layerSource = getLayerBySceneLayerSource(buildingType);
        if (groupKey) layerSource?.addHandles(handler, groupKey);
        return handler;
    }
};

export const addHighlightBuildingWithPin = async (
    buildingType: SceneLayerSource,
    buildingId: number,
    groupKey?: string
): Promise<BuildingWithPin | undefined> => {
    let pinHighlight: IHandle | undefined;

    const pinSource = getPinBasedOnBuildingType(buildingType);
    if (pinSource) {
        pinHighlight = await addHighlightBuilding(pinSource, buildingId, groupKey);
    }
    const buildingHighlight = await addHighlightBuilding(buildingType, buildingId, groupKey);
    if (!pinHighlight || !buildingHighlight) {
        pinHighlight?.remove();
        buildingHighlight?.remove();
        return;
    }
    return { buildingHighlight, pinHighlight };
};

export const removeHighlightingFromLayer = (layer?: Layer, groupKey?: string) => {
    if (layer && groupKey && layer.hasHandles?.(groupKey)) {
        layer.removeHandles(groupKey);
    }
};

export const removeHighlightBuilding = (
    buildingType: SceneLayerSource | SceneLayerSource[],
    groupKey: string
) => {
    const buildingTypes = Array.isArray(buildingType) ? buildingType : [buildingType];

    for (const type of buildingTypes) {
        const layerSource = getLayerBySceneLayerSource(type);
        removeHighlightingFromLayer(layerSource, groupKey);
    }
};

export const removeAllBuildingHighlights = async (buildingType: SceneLayerSource) => {
    const layerSource = getLayerBySceneLayerSource(buildingType);
    layerSource?.removeHandles();
};

export const removeBuildingHighlights = () => {
    removeHighlightBuilding('building-edits-layer', BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY);
    removeHighlightBuilding('osm', OSM_BUILDINGS_MAP_CLICK_EVENT_KEY);
    removeHighlightBuilding(
        ['dev-pipeline', 'highlighted-dev-pipeline', 'devpipeline-pins'],
        DEV_PIPELINE_MAP_CLICK_EVENT_KEY
    );
};

export const useGetMarketSpherePropertyIdByRef = () => {
    const mappingLookupByOsmId = useAppSelector(selectMappingLookupByOsmId);

    const getMarketSpherePropertyIdByRef = useCallback(
        async (geometryRef: BuildingGeometryRef) => {
            switch (geometryRef.layerType) {
                case 'dev-pipeline':
                case 'highlighted-dev-pipeline':
                    return await findMarketSphereIdFromBlackbirdId(geometryRef.id);
                case 'building-edits-layer':
                    return await searchMarketSphereIdByBlackbirdId(geometryRef.id);
                default:
                    return mappingLookupByOsmId[geometryRef.id]?.marketSpherePropertyId;
            }
        },
        [mappingLookupByOsmId]
    );

    return getMarketSpherePropertyIdByRef;
};
