import LineOfSightAnalysisObserver from '@arcgis/core/analysis/LineOfSightAnalysisObserver';
import LineOfSightAnalysisTarget from '@arcgis/core/analysis/LineOfSightAnalysisTarget';
import Viewshed from '@arcgis/core/analysis/Viewshed';
import Color from '@arcgis/core/Color';
import Collection from '@arcgis/core/core/Collection';

import LibraryLayerTreeItem, {
    LibraryLayerTreeItemWithMetadata,
    WidgetLayerTreeItem,
} from '../types/Layers/LibraryLayerTreeItem';
import {
    LOSLayerMetadata,
    ShadowCastLayerMetadata,
    ViewshedLayerMetadata,
    WidgetLayerMetadata,
    WidgetLayerSaveState,
    WidgetLayerSettings,
    WidgetTypes,
} from '../types/Layers/WidgetLayerMetadata';
import { nanoid } from '../utils/idUtils';

export const createLOSLayerLibraryItem = (
    observer: LineOfSightAnalysisObserver,
    targets: LineOfSightAnalysisObserver[]
): WidgetLayerTreeItem => {
    const key = `WidgetLayer--${nanoid()}--LOS`;
    const metadata: WidgetLayerMetadata = {
        type: 'LOS',
        settings: {
            observer,
            targets,
        } as LOSLayerMetadata,
    };
    return {
        id: 0,
        title: 'LOS Result',
        itemType: 'WidgetLayer',
        key,
        metadata,
        checked: true,
        active: true,
        legendEnabled: false,
    } as WidgetLayerTreeItem;
};

export const createViewshedLayerLibraryItem = (viewshed: Viewshed): WidgetLayerTreeItem => {
    const key = `WidgetLayer--${nanoid()}--Viewshed`;
    const metadata: WidgetLayerMetadata = {
        type: 'Viewshed',
        settings: { viewshed: viewshed } as ViewshedLayerMetadata,
    };
    return {
        id: 0,
        title: 'Viewshed Result',
        itemType: 'WidgetLayer',
        key,
        metadata,
        checked: true,
        active: true,
        legendEnabled: false,
    } as WidgetLayerTreeItem;
};

export const createShadowCastLayerLibraryItem = (
    settings: ShadowCastLayerMetadata
): WidgetLayerTreeItem => {
    const key = `WidgetLayer--${nanoid()}--ShadowCast`;
    const metadata: WidgetLayerMetadata = {
        type: 'ShadowCast',
        settings: settings,
    };
    return {
        id: 0,
        title: 'Shadow Cast Result',
        itemType: 'WidgetLayer',
        key,
        metadata,
        checked: true,
        active: true,
        legendEnabled: false,
    } as WidgetLayerTreeItem;
};

export const transformShadowCastLayerMetadata = (
    settings: ShadowCastLayerMetadata
): __esri.ShadowCastViewModelProperties => {
    const {
        discreteOptions,
        durationOptions,
        thresholdOptions,
        visualizationType,
        date,
        startTimeOfDay,
        endTimeOfDay,
        utcOffset,
    } = settings;

    return {
        discreteOptions: {
            interval: discreteOptions.interval,
            intervalOptions: new Collection(discreteOptions.intervalOptions ?? []),
            color: discreteOptions.color ? Color.fromHex(discreteOptions.color) : undefined,
        },
        durationOptions: {
            mode: durationOptions.mode,
            color: durationOptions.color ? Color.fromHex(durationOptions.color) : undefined,
        },
        thresholdOptions: {
            minValue: thresholdOptions.minValue,
            maxValue: thresholdOptions.maxValue,
            value: thresholdOptions.value,
            color: thresholdOptions.color ? Color.fromHex(thresholdOptions.color) : undefined,
        },
        utcOffset,
        date,
        startTimeOfDay,
        endTimeOfDay,
        visualizationType,
    };
};

export const createWidgetLayerSaveState = (
    libraryItem: LibraryLayerTreeItemWithMetadata
): WidgetLayerSaveState => {
    const { checked, title } = libraryItem;
    const metadata = libraryItem.metadata as WidgetLayerMetadata;
    return {
        title: title ?? '',
        checked: !!checked,
        settings: metadata,
    };
};

const handleViewshedWidgetState = (metadata: ViewshedLayerMetadata) => {
    const viewshed = new Viewshed({
        ...metadata.viewshed,
    });

    return {
        type: 'Viewshed',
        settings: {
            viewshed: viewshed,
        } as ViewshedLayerMetadata,
    } as WidgetLayerMetadata;
};

const handleLOSWidgetState = (metadata: LOSLayerMetadata) => {
    const observerData = metadata.observer;
    const targetsData = metadata.targets;
    const observer = new LineOfSightAnalysisObserver({
        ...observerData,
    });
    const targets = // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
        targetsData.map((target: any) => {
            return new LineOfSightAnalysisTarget({
                ...target,
            });
        });
    return {
        type: 'LOS',
        settings: {
            observer: observer,
            targets: targets,
        } as LOSLayerMetadata,
    } as WidgetLayerMetadata;
};

const handleShadowCastWidgetState = (settings: ShadowCastLayerMetadata) => {
    return {
        type: 'ShadowCast',
        settings: settings,
    } as WidgetLayerMetadata;
};

export const deserializeWidgetSettings = (settings: WidgetLayerMetadata) => {
    const widgetType = settings?.type as WidgetTypes;
    if (!widgetType) return settings;
    const metadata = settings.settings as WidgetLayerSettings;
    switch (widgetType) {
        case 'LOS':
            return handleLOSWidgetState(metadata as LOSLayerMetadata);
        case 'Viewshed':
            return handleViewshedWidgetState(metadata as ViewshedLayerMetadata);
        case 'ShadowCast':
            return handleShadowCastWidgetState(metadata as ShadowCastLayerMetadata);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createWidgetLayerFromSavedState = (layer: any): LibraryLayerTreeItem => {
    const itemType = 'WidgetLayer';
    const { guid, name, settings } = layer;
    const key = guid ?? `${itemType}--${nanoid()}`;
    const metadata = deserializeWidgetSettings(settings);
    return {
        id: 0,
        itemType,
        key,
        title: name,
        metadata,
        checked: layer.active,
        legendEnabled: false,
    };
};
